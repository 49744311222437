/* override calendar background color */
.rbc-event {
  background-color: #51cbce;
}
.m-footer {
  padding-right: 20px;
}
.cal-modal-header .btn-close {
  display: none;
}

.ReactTable .compact-table .expander, .ReactTable .compact-table .rt-thead .expander {
  flex: 0.1;
}
.ReactTable .compact-table .avg-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.compact-expanded th.right {
  text-align: right;
}

.loading {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background: rgba(255,255,255,0.5);
}
.loading .visually-hidden {
  visibility: hidden;
}