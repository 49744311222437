.notification-icon__counter {
  position: absolute;
  background-color: #FB404B;
  text-align: center;
  border-radius: 10px;
  min-width: 16px;
  padding: 0 5px;
  height: 16px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 16px;
  top: 7px;
  left: 7px;
}

.notification-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.notification-item__icon {
  margin-right: 10px;
  vertical-align: middle;
}

.notification-item__title {
  font-weight: bold;
  margin: 0 10px;
}

.notification-item__time {
  margin-left: auto;
  font-weight: 400;
}
