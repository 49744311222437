.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}
