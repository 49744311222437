.dropup,
.dropdown{
    .dropdown-toggle:after{
        margin-left: 3px;
    }
}

.rt-td {
    .dropdown {
        position: absolute;
        .btn {
            margin: 0;
        }
        .dropdown-menu{
            &[x-placement="bottom-start"]{
                @include transform-translate-dropdown(-40%, 0px);
            }
        }
        &.show .dropdown-menu{
            &[x-placement="bottom-start"]{
                @include transform-translate-dropdown(-40%, 40px);
            }
        }
    }
}

.dropdown-menu{
    .dropdown-item{
        padding: 10px 15px;
        color: inherit;
        &.disabled{
            color: #66615b;
        }
    }
}
