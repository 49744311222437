#balance-board {
  text-align: left;
  font-size: 48px;
  font-style: oblique;
}

#balance-board-decimal {
  text-align: left;
  font-size: 36px;
  font-style: oblique;
}
