.remove-question-button *,
.shuffle-question-button * {
    pointer-events: none;
}

.question-row {
    cursor: pointer;
}

.exam-editor-question-table .table-responsive,
.exam-editor-resource-table .table-responsive {
    overflow: auto;
}

.ql-exam-editor .ql-editor table[table_id='null'] tr[row_id='null'] td[cell_id='null'] {
    border: none;
}

.exam-editor-buttons {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #FFFFFF;
}

/*.ql-clipboard {*/
/*    left: -100000px;*/
/*    height: 1px;*/
/*    overflow-y: hidden;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    white-space: pre-wrap;*/
/*    word-wrap: break-word;*/
/*}*/
